import React, { useState,useEffect } from 'react';
import axios from 'axios';
import jsYaml from 'js-yaml';
import AceEditor from 'react-ace';
import 'ace-builds/src-noconflict/mode-yaml';
import 'ace-builds/src-noconflict/theme-monokai';

export default function GetRepos() {
    const [repos, setRepos] = useState(["select repo"]);
    const [prowjobs, setProwjobs] = useState(["select job"]);
    const [prs, setprs] = useState(["select pr"]);
    const [selectedRepo, setSelectedRepo] = useState('');
    const [selectJobType, setSelectJobType] = useState('periodic');
    const [selectJob, setSelectJob] = useState('');
    const [OpenPRs, setOpenPRs] = useState('');
    const [yamlContent, setYamlContent] = useState('');
    const [originalData, setOriginalData] = useState(null);
    const [branch,setBranch] = useState("")
    const [joburl,setjoburl] = useState("")
  
    const checKBranch = () => {
        if (selectJobType === "postsubmit" && branch === "") {
            alert("Branch name is required")
            return false
        }
        return true
    }
    
    const handleOnClick = async () => {
      if (checKBranch()) {
        try {
                const baseurl = "https://prowjobeditor.k8strilio.net"
                const url = `/api/v1/yaml?jobtype=${encodeURIComponent(selectJobType)}&pr=${encodeURIComponent(OpenPRs)}&branch=${encodeURIComponent(branch)}&jobname=${encodeURIComponent(selectJob)}&reponame=${encodeURIComponent(selectedRepo)}`;
                const response = await axios.get(baseurl+url, {
                    headers: {"Access-Control-Allow-Origin": "*"}
                });
                if (response.status ===200) {
                const data = response.data;
                const yamlString = jsYaml.dump(data);
                setYamlContent(yamlString);
                setOriginalData(data)
                }
            } catch (error) {
                console.error('Error fetching data from backend:', error);
                if (error.response.status === 404) {
                alert(branch+" not found");
                }
                console.log(selectJobType,branch)
            }
        }
        setjoburl("")
    }
  
  
    const OnSubmit = async () => {
      try {
          const yamlObject = jsYaml.safeLoad(yamlContent);
          const jsonOutput = JSON.stringify(yamlObject, null, 2);
          const baseurl = "https://prowjobeditor.k8strilio.net"
          const url = `/api/v1/yaml`;
          const response = await axios.post(baseurl+url,jsonOutput,{
              headers: {
                  "Content-Type": "application/json",
                  "Access-Control-Allow-Origin": "*",
              }
          });
          setOriginalData(yamlContent);

          if (response.status === 200){
            setjoburl(response.data.url)
          } 
        } catch (error) {
          alert("Error running job")
          console.error('Error fetching data from backend:', error);
        }
    }
  
    const handleYamlChange = (newValue) => {
      setYamlContent(newValue);
    };
  useEffect(() => {
      fetchRepos();
  }, []);
    const fetchRepos = async () => {
        try {
            const baseurl = "https://prowjobeditor.k8strilio.net"
            const response = await axios.get(baseurl+'/api/v1/repos',{
                headers: {"Access-Control-Allow-Origin": "*"}
            });
            const data = response.data;
            if (response.status === 200) {
                setRepos(data.repos);
                setSelectedRepo(data.repos[0])
            } else {
                setRepos([]);
                setSelectedRepo("")
            }
        } catch (error) {
            console.error('Error fetching data from backend:', error);
        }
    };

    const fetchJobs = async (jobtype,repo) => {
        const baseurl = "https://prowjobeditor.k8strilio.net"
        const url = `/api/v1/jobs?jobtype=${encodeURIComponent(jobtype)}&reponame=${encodeURIComponent(repo)}`;
    
        try {
            const response = await axios.get(baseurl+url,{
                headers: {"Access-Control-Allow-Origin": "*"}
            });
            const data = response.data;
            if (response.status === 200) {
                setProwjobs(data.prowjobs)
                setSelectJob(data.prowjobs[0])
            } else {
                setProwjobs([])
                setSelectJob("")
            }
            
        } catch (error) {
            console.error('Error fetching jobs:', error);
        }
    };

    const fetchPRs = async (jobtype,repo) => {
        if (jobtype === "presubmit") {
            const baseurl = "https://prowjobeditor.k8strilio.net"
            const url = `/api/v1/pr?reponame=${encodeURIComponent(repo)}`;

            try {
                const response = await axios.get(baseurl+url,{
                    headers: {"Access-Control-Allow-Origin": "*"}
                });
                const data = response.data;
                if (response.status === 200) {
                    setprs(data.prs)
                    setOpenPRs(data.prs[0])
                } else {
                    setprs([])
                    setOpenPRs("")
                }
            } catch (error) {
                console.error('Error fetching jobs:', error);
            }
        } else {
            setprs([])
            setOpenPRs([])
        }
    };

    const hancleonClean =  () => {
        setOriginalData(null)
        setjoburl("")
    }

    const handleSelectJobType = (event) => {
        if (event.target.value === "periodic"|| event.target.value === "postsubmit") {
            setOpenPRs("")
        }
        setSelectJobType(event.target.value)
        fetchJobs(event.target.value,selectedRepo)
        fetchPRs(event.target.value,selectedRepo)
    };
    
    const handleSelectRepo = (event) => {
        if (selectJobType === "periodic"|| selectJobType === "postsubmit") {
            setOpenPRs("")
        }
        setSelectedRepo(event.target.value);
        fetchJobs(selectJobType,event.target.value)
        fetchPRs(selectJobType,event.target.value)
    };

    const handleSelectJob = (event) => {
        if (selectJobType === "periodic"|| selectJobType === "postsubmit") {
            setOpenPRs("")
        }
        setSelectJob(event.target.value)
    };
    const hadleOpenPr = (event) => {
        setOpenPRs(event.target.value)
    };

    const handleInput = (event) => {
        console.log(event.target.value)
        setBranch(event.target.value)
    }

    const handleOnSubmit = (event) => {
        event.preventDefault()
    }

  return (
    <>
    <div>
    <select className="jobtype" value={selectJobType} aria-label="Default select" onChange={handleSelectJobType}>
        <option value="periodic">Periodic</option>
        <option value="presubmit">Presubmit</option>
        <option value="postsubmit">Postsubmit</option>
    </select>
    <select className="reponame" value={selectedRepo} onChange={handleSelectRepo} disabled={selectJobType === "periodic"}>
    {repos.map((repo, index) => (
        <option key={index} value={repo}>
            {repo}
        </option>
    ))}
    </select>
    <select className="jobsname" value={selectJob} onChange={handleSelectJob} >
    {prowjobs.map((prowjobs, index) => (
        <option key={index} value={prowjobs}>
            {prowjobs}
        </option>
    ))}
    </select>
    <select className="prs" value={OpenPRs} onChange={hadleOpenPr} disabled={selectJobType === "periodic"|| selectJobType === "postsubmit"} >
    {prs.map((prs, index) => (
        <option key={index} value={prs}>
            {prs}
        </option>
    ))}
    </select>
    <form className="d-flex"  onSubmit={handleOnSubmit}>
        <input className="form-control" type="text" placeholder="Branch name (e.g. master)" value={branch} onChange={handleInput} disabled={selectJobType === "presubmit"}/> 
        <button type="button" className="btn btn-primary" onClick={handleOnClick} >GetYaml</button>
    </form>
    </div>
    <div>
      { originalData ? (
        <>
          <AceEditor
            className='yamleditor'
            mode="yaml"
            theme="monokai"
            value={yamlContent}
            onChange={handleYamlChange}
            width="75%"
            height="500px"
            fontSize={14}
            editorProps={{ $blockScrolling: true }}
          />
        </>
      ) : (
        <p></p>
      )}
    </div>
    <div>
        <p>Job Url: <a href={joburl}>{joburl}</a></p>
    </div>
    <button type="button" className="btn btn-primary" onClick={OnSubmit} disabled={originalData === null}>Submit</button>
    <button type="button" className="btn btn-primary" onClick={hancleonClean}>Clean</button>
    </>
  )
}
