import React from 'react'
import PropTypes from 'prop-types'

export default function Navbar(props) {
  return (
    <nav className="navbar bg-primary" data-bs-theme="dark">
        <div className="container-fluid">
            <a className="navbar-brand" href="/">
                <img src={props.imagepath} alt="Logo" className="d-inline-block align-text-top" />
                <b>ProwJobEditor</b>
            </a>
        </div>
    </nav>
  )
}


Navbar.propTypes = {
    imagepath: PropTypes.string
}

Navbar.defaultProps = {
    imagepath: "./logo-light.png",
}